import CropperElement from '@cropper/element';
import { CROPPER_CROSSHAIR } from '@cropper/utils';

var style = `:host{display:inline-block;height:1em;position:relative;touch-action:none;-webkit-user-select:none;-moz-user-select:none;user-select:none;vertical-align:middle;width:1em}:host:after,:host:before{background-color:var(--theme-color);content:"";display:block;position:absolute}:host:before{height:1px;left:0;top:50%;transform:translateY(-50%);width:100%}:host:after{height:100%;left:50%;top:0;transform:translateX(-50%);width:1px}:host([centered]){left:50%;position:absolute;top:50%;transform:translate(-50%,-50%)}`;

class CropperCrosshair extends CropperElement {
    constructor() {
        super(...arguments);
        this.$style = style;
        this.centered = false;
        this.slottable = false;
        this.themeColor = 'rgba(238, 238, 238, 0.5)';
    }
    static get observedAttributes() {
        return super.observedAttributes.concat([
            'centered',
        ]);
    }
}
CropperCrosshair.$name = CROPPER_CROSSHAIR;
CropperCrosshair.$version = '2.0.0-beta.4';

export { CropperCrosshair as default };
